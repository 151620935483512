<template>
  <main-layout>
    <h1 class="supportPage__pageTitle">{{ $t("sidebarSupport") }}</h1>
    <div class="supportPage__container">
      <div class="supportPage__formTitle">{{ $t("supportContactHeader") }}</div>
      <div class="supportPage__formSubtitle">
        {{ $t("fillSupportForm") }}
      </div>
      <div class="supportPage__textareaWrapper">
        <textarea
          class="supportPage__textarea"
          :class="{ 'supportPage__textarea-error': v$.$error }"
          :placeholder="$t('Message')"
          v-model="massage"
        />
        <small v-if="v$.$error">{{ $t("fieldRequireError") }}</small>
      </div>
      <div class="supportPage__btnWrapper">
        <base-button
          :is-loaded="imageIsLoad"
          @click="sendContactForm()"
          class="supportPage__btn"
        >
          {{ $t("sendMessage") }}
        </base-button>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseInput from "@/components/UI/inputs/BaseInput";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "SupportPage",
  components: { BaseButton, BaseInput, MainLayout },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();
    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      massage: null,
    };
  },
  validations() {
    return {
      massage: { required },
    };
  },
  methods: {
    sendContactForm() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      this.$store.commit("SET_LOADED_STATE", true);
      this.$http
        .post("email/contact-support", {
          message: this.massage,
        })
        .then(() => {
          this.toast.success(this.$t("messageSuccessfullySend"));
          this.$store.commit("SET_LOADED_STATE", false);
          this.$router.push("/");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      imageIsLoad: "LOADED_STATE",
    }),
  },
};
</script>

<style></style>
